<template>
  <div class="console">
    <div class="left-nav">
      <div class="navbtnbox">
        <div class="btn-style" @click="toOverView">
          数据大屏
        </div>
      </div>
      <Menu
        :width="'auto'"
        @on-select="handleNavChange"
        :open-names="openName"
        :active-name="activeName"
      >
        <MenuItem name="project">
          <Icon type="md-document" />项目管理
        </MenuItem>
        <MenuItem name="personnel">
          <Icon type="md-chatbubbles" />人员管理
        </MenuItem>
      </Menu>
    </div>
    <div class="right-main">
      <div class="content-box" v-if="currentBox == 'project'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            项目条件筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="">
              <div class="mb-item">
                <div class="label">工程状态：</div>
                <Select
                  v-model="searchProjectData.projectStatus"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in projectStatus"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="mb-item">
                <div class="label">所属区域：</div>
                <Select
                  v-model="searchProjectData.areaId"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in projectArea"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
              <div class="mb-item">
                <div class="label">抖工平台状态：</div>
                <Select
                  v-model="searchProjectData.platformStatus"
                  style="width: 200px; margin-right: 14px"
                >
                  <Option
                    v-for="item in platformStatus"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
            </div>
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="">
              <div class="mb-item">
                <div class="label">工程序号：</div>
                <Input
                  v-model="searchProjectData.safeCorpNum"
                  placeholder="请输入工程序号"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">施工许可证号：</div>
                <Input
                  v-model="searchProjectData.recordCode"
                  placeholder="请输入施工许可证号"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
            </div>
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <div class="mb-item">
                <div class="label">项目名称：</div>
                <Input
                  v-model="searchProjectData.name"
                  placeholder="请输入项目名称"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">建设单位：</div>
                <Input
                  v-model="searchProjectData.buildCorpName"
                  placeholder="请输入建设单位"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">施工单位：</div>
                <Input
                  v-model="searchProjectData.contractorCorpName"
                  placeholder="请输入施工单位"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
            </div>
            <div class="c-btnbox-item">
              <!-- <Button type="primary" shape="circle" style="margin-right:14px;" icon="md-add">批量导入失信人员</Button> -->
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleProjectSearch('search')"
                >搜索</Button
              >
              <Button
                style="margin-right: 14px"
                @click="handleProjectSearch('reset')"
                >重置</Button
              >
            </div>
            <div class="c-btnbox-item"></div>
          </div>
          <div class="second-title">
            <div class="shu" />
            批量操作
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <Button
                style="margin-right: 14px"
                icon="ios-cloud-download-outline"
                @click="downLoadProjectListModel"
                >导入模板下载</Button
              >
              <Upload
                action="/sapi/project/upload"
                :name="'file'"
                :multiple="false"
                :before-upload="onBefore"
                :accept="Accept"
                :format="Format"
                :data="uploadData"
              >
                <Button icon="ios-cloud-upload-outline">批量导入</Button>
              </Upload>
            </div>
          </div>
          <Table
            :columns="projectColumsList"
            :data="projectDataList"
            :loading="projectLoadingList"
          >
            <template slot-scope="{ row, index }" slot="action">
              <Button
                type="warning"
                @click="toDetail(row)"
                style="z-index: 10"
                >详情</Button
              >
            </template>
          </Table>
          <Page
            class="page-box"
            :total="projectListTotal"
            :page-size="projectListSize"
            @on-change="handleProjectPageChange"
          />
          <!-- <Page class="page-box" :total="100" /> -->
        </Card>
      </div>
      <div class="content-box" v-if="currentBox == 'personnel'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            管理人员条件筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <div class="mb-item">
                <div class="label" style="display: flex; width: 45px">
                  姓名：
                </div>
                <Input
                  v-model="searchPersonnelName"
                  placeholder="请输入姓名"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handlePersonnelSearch('search')"
                >搜索</Button
              >
              <Button
                style="margin-right: 14px"
                @click="handlePersonnelSearch('reset')"
                >重置</Button
              >
            </div>
            <div class="c-btnbox-item">
              <Button type="info" @click="toAddPersonnel" >添加管理人员</Button>
            </div>
          </div>
          <div class="second-title">
            <div class="shu" />
            管理人员列表
          </div>
          <Table
            :columns="personnelColumsList"
            :data="personnelDataList"
            :loading="personnelListLoading"
          >
            <template slot-scope="{ row, index }" slot="action">
              <div class="btnbox-flex">
                <Button type="success" @click="toPersonnelDetail(row)"  >
                  详情
                </Button>
              </div>
            </template>
          </Table>
          <Page
            class="page-box"
            :total="personnelListTotal"
            :page-size="personnelListSize"
            @on-change="handlePersonnelPageChange"
          />
        </Card>
      </div>
      <div class="content-box" v-if="currentBox == 'safelist'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            安全技术交底表
          </div>
          <Cascader
            :data="safeSelectData"
            v-model="safeSelectValue"
            style="width: 500px; margin-bottom: 14px; margin-left: 14px"
            placeholder="请选择安全交底表格"
            @on-change="handleSafeSelectChange"
            filterable
          />
          <Button
            style="margin-left: 14px"
            type="success"
            icon="ios-copy-outline"
            @click="updateSafeDisclose"
            >获取企业模板</Button
          >
          <Button
            style="margin-left: 14px"
            type="primary"
            icon="ios-download-outline"
            @click="downSafeList"
            >下载安全技术交底表</Button
          >
          <div class="second-title" style="margin-top: 14px">
            <div class="shu" />
            安全技术交底表内容
          </div>
          <div class="tab-btnbox">
            <Button
              style="margin-right: 8px"
              @click="toEditSafeContent"
              type="info"
              v-if="!editSafeFlag"
              >编辑安全技术交底表</Button
            >
            <Button
              style="margin-right: 8px"
              type="success"
              v-if="editSafeFlag"
              @click="toChangeSafeContent('save')"
              >保存</Button
            >
            <Button
              style="margin-right: 8px"
              v-if="editSafeFlag"
              @click="toChangeSafeContent('cancel')"
              >取消</Button
            >
          </div>
          <div style="position: relative">
            <Spin fix v-if="getSafeContentLoading">
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              ></Icon>
              <div>Loading</div>
            </Spin>
            <Input
              v-model="currentSafeContent"
              :disabled="!editSafeFlag"
              :autosize="true"
              class="tab-textarea"
              type="textarea"
              placeholder="安全交底表内容"
            />
          </div>
        </Card>
      </div>
    </div>
    <div class="detail-box" v-if="projectDetailFlag">
      <div class="content-box">
        <div class="title">项目详情</div>
        <div class="infobox">
          <div class="i-item">
            <div class="label">项目名称：</div>
            <Input
              v-model="projectDetailData.name"
              placeholder="请输入项目名称"
              style="width: 200px"
            />
          </div>
          <div class="i-item">
            <div class="label">工程序号：</div>
            <Input
              v-model="projectDetailData.safeCorpNum"
              placeholder="请输入工程序号"
              style="width: 200px"
            />
          </div>
          <div class="i-item">
            <div class="label">施工许可证：</div>
            <Input
              v-model="projectDetailData.recordCode"
              placeholder="请输入施工许可证"
              style="width: 200px"
            />
          </div>
          <div class="i-item">
            <div class="label">建设单位：</div>
            <Input
              v-model="projectDetailData.buildCorpName"
              placeholder="请输入建设单位"
              style="width: 200px"
            />
          </div>
          <div class="i-item">
            <div class="label">施工单位：</div>
            <Input
              v-model="projectDetailData.contractorCorpName"
              placeholder="请输入施工单位"
              style="width: 200px"
            />
          </div>
          <!-- <div class="i-item">
            <div class="label">监理单位：</div>
            <Input
              v-model="projectDetailData.contractorCorpName"
              placeholder="请输入监理单位"
              style="width: 200px;"
            />
          </div> -->
          <div class="i-item">
            <div class="label">工程状态：</div>
            <!-- <Input
              v-model="projectDetailData.projectStatusStr"
              placeholder="请输入工程状态"
              style="width: 200px;"
            /> -->
            <Select
              v-model="projectDetailData.projectStatus"
              style="width: 200px; margin-right: 14px"
            >
              <Option
                v-for="item in projectStatus"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
          <div class="i-item">
            <div class="label">项目负责人姓名：</div>
            <Input
              v-model="projectDetailData.responsiblePersonName"
              placeholder="请输入项目负责人姓名"
              style="width: 200px"
            />
          </div>
          <div class="i-item">
            <div class="label">项目负责人手机号码：</div>
            <Input
              v-model="projectDetailData.responsiblePersonPhone"
              placeholder="请输入项目负责人手机号码"
              style="width: 200px"
            />
          </div>
          <!-- <div class="i-item">
            <div class="label">项目地址：</div>
            <Input
              v-model="projectDetailData.address"
              placeholder="请输入项目地址"
              style="width: 200px;"
            />
          </div> -->
          <div class="i-item">
            <div class="label">抖工平台状态：</div>
            <Select
              v-model="projectDetailData.platformStatus"
              style="width: 200px; margin-right: 14px"
            >
              <Option
                v-for="item in platformStatus"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
          <!-- <div class="i-item">
            <div class="label">区域负责人：</div>
            <Input
              v-model="projectDetailData.platformStatus"
              placeholder="请输入区域负责人"
              style="width: 200px;"
            />
          </div> -->
        </div>
        <div class="btnbox">
          <Button type="error" style="margin-right: 14px" @click="toDelProject">删除</Button>
          <Button type="success" style="margin-right: 14px" @click="toSaveProject">保存</Button>
          <Button @click="cancelDetail">取消</Button>
        </div>
      </div>
    </div>
    <div class="detail-box" v-if="personnelDetailFlag">
      <div class="content-box">
        <div class="title">人员信息管理</div>
        <div class="infobox">
          <div class="i-item">
            <div class="label">姓名：</div>
            <!-- <Input
              v-model="personnelDetailData.name"
              placeholder="请输入项目名称"
              style="width: 200px"
            /> -->
            <div>{{ personnelDetailData.name }}</div>
          </div>
          <div class="i-item">
            <div class="label">手机号码：</div>
            <!-- <Input
              v-model="personnelDetailData.phone"
              placeholder="请输入工程序号"
              style="width: 200px"
            /> -->
            <div>{{ personnelDetailData.phone }}</div>
          </div>
          <div class="i-item">
            <div class="label">管辖区域：</div>
            <Select v-model="currentAreaList" multiple style="width: 260px">
              <Option
                v-for="item in projectArea"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="btnbox">
          <Button
            type="error"
            style="margin-right: 14px"
            @click="toDelPersonnelData"
            >删除</Button
          >
          <Button
            type="success"
            style="margin-right: 14px"
            @click="toSavePersonnelData"
            >保存</Button
          >
          <Button @click="cancelPersonnelDetail">取消</Button>
        </div>
      </div>
    </div>
    <div class="detail-box" v-if="personnelAddFlag">
      <div class="content-box">
        <div class="title">人员信息管理</div>
        <div class="infobox">
          <div class="i-item">
            <div class="label">姓名：</div>
            <Input
              v-model="addPersonnelData.name"
              placeholder="请输入姓名"
              style="width: 200px"
            />
          </div>
          <div class="i-item">
            <div class="label">手机号码：</div>
            <Input
              v-model="addPersonnelData.phone"
              placeholder="请输入手机号码"
              style="width: 200px"
            />
          </div>
          <div class="i-item">
            <div class="label">管辖区域：</div>
            <Select v-model="addPersonnelData.areaList" multiple style="width: 260px">
              <Option
                v-for="item in projectArea"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="btnbox">
          <Button
            type="success"
            style="margin-right: 14px"
            @click="toAddPersonnelData"
            >保存</Button
          >
          <Button @click="cancelAddPersonnel">取消</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myConsole",
  data() {
    return {
      Accept: ".xls,.xlsx", //上传文件格式限制
      Format: [".xls", ".xlsx"], //上传文件格式限制
      uploadData: {},
      currentUserInfo: {},
      activeName: "project",
      openName: ["project"],
      currentBox: "project",
      projectListPage: 1,
      projectListSize: 15,
      projectListTotal: 0,
      searchProjectData: {
        // cityId: '',
        areaId: "",
        platformStatus: "", //平台状态
        name: "", //项目名
        projectStatus: "", //项目状态
        safeCorpNum: "", //工程序号
        recordCode: "", //施工许可证号
        buildCorpName: "", //建设单位
        contractorCorpName: "", //施工单位
      },
      newAuthData: {
        name: "",
        phone: "",
      },
      projectStatus: [
        {
          value: "001",
          label: "筹备",
        },
        {
          value: "003",
          label: "在建",
        },
        {
          value: "004",
          label: "完工",
        },
        {
          value: "005",
          label: "停工",
        },
      ],
      projectArea: [
        { label: "斗门区", value: "440403" },
        { label: "高栏港区", value: "440493" },
        { label: "金湾区", value: "440404" },
        { label: "高新区", value: "440492" },
        { label: "香洲区", value: "440402" },
        { label: "保税区", value: "440491" },
        { label: "横琴新区", value: "440495" },
        { label: "万山区", value: "440494" },
      ],
      platformStatus: [
        {
          value: 0,
          label: "未注册",
        },
        {
          value: 1,
          label: "已注册",
        },
      ],
      projectLoadingList: false,
      projectColumsList: [
        {
          title: "工程序号",
          key: "safeCorpNum",
          width: "100",
        },
        {
          title: "施工许可证号",
          key: "recordCode",
          width: "120",
        },
        {
          title: "项目名称",
          key: "name",
          width: "200",
        },
        {
          title: "建设单位",
          key: "buildCorpName",
          width: "200",
        },
        {
          title: "施工单位",
          key: "contractorCorpName",
          width: "100",
        },
        {
          title: "负责人姓名",
          key: "responsiblePersonName",
          width: "120",
        },
        {
          title: "负责人手机号码",
          key: "responsiblePersonPhone",
          width: "140",
        },
        {
          title: "区域",
          key: "areaStr",
          width: "100",
        },
        {
          title: "工程状态",
          key: "projectStatusStr",
          width: "100",
        },
        {
          title: "抖工平台状态",
          key: "platformStatusStr",
          width: "140",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      projectDataList: [],
      file: null,
      projectDetailFlag: false,
      projectDetailData: {},
      projectDetailBackUpData: {},
      personnelColumsList: [
        {
          title: "姓名",
          key: "name",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "管辖区域",
          key: "areaIdsStr",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      personnelDataList: [],
      personnelListLoading: false,
      personnelListPage: 1,
      personnelListTotal: 0,
      personnelListSize: 15,
      searchPersonnelName: "",
      personnelDetailFlag: false,
      personnelDetailData: {},
      personnelDetailBackUpData: {},
      currentAreaList: [],
      personnelAddFlag: false,
      addPersonnelData: {
        name: '',
        phone: '',
        areaList: []
      }
    };
  },
  mounted() {
    let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
    if (userInfo) {
      this.currentUserInfo = userInfo;
      // 获取项目管理列表
      this.getProjectList();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  watch: {
    openName() {
      this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      });
    },
  },
  methods: {
    handleNavChange(e) {
      this.currentBox = e;
      if (e == "project") {
        this.projectListPage = 1;
        this.searchProjectData = {
          // cityId: '',
          areaId: "",
          platformStatus: "", //平台状态
          name: "", //项目名
          projectStatus: "", //项目状态
          safeCorpNum: "", //工程序号
          recordCode: "", //施工许可证号
          buildCorpName: "", //建设单位
          contractorCorpName: "", //施工单位
        };
        this.getProjectList();
      }
      if (e == "personnel") {
        this.personnelListPage = 1;
        this.getPersonnelList();
      }
    },
    handleProjectPageChange(e) {
      this.projectListPage = e;
      this.getProjectList();
    },
    handleProjectSearch(type) {
      if (type == "reset") {
        this.searchProjectData = {
          // cityId: '',
          areaId: "",
          platformStatus: "", //平台状态
          name: "", //项目名
          projectStatus: "", //项目状态
          safeCorpNum: "", //工程序号
          recordCode: "", //施工许可证号
          buildCorpName: "", //建设单位
          contractorCorpName: "", //施工单位
        };
      }
      this.projectListPage = 1;
      this.getProjectList();
    },
    downLoadProjectListModel() {
      let downUrl = ''
      downUrl = this.common.openUrl('9000','rlapi',`/template/statProjectTemplate`);
      window.open(downUrl, "_blank");
    },
    onBefore(file) {
      this.file = file;
      this.senBlackList();
      return false;
    },
    senBlackList() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("cityId", '440400');
      this.$fetch("/sapi/project/upload", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type":
            "multipart/form-data;boundary = " + new Date().getTime(),
        },
      })
        .then((res) => {
          if (res.code == "200") {
            this.file = null;
            this.$Message.success("导入成功");
            this.projectListPage = 1;
            this.searchProjectData = {
              // cityId: '',
              areaId: "",
              platformStatus: "", //平台状态
              name: "", //项目名
              projectStatus: "", //项目状态
              safeCorpNum: "", //工程序号
              recordCode: "", //施工许可证号
              buildCorpName: "", //建设单位
              contractorCorpName: "", //施工单位
            };
            this.getProjectList();
          } else {
            this.file = null;
            this.$Message.error("导入失败，稍后再试");
          }
        })
        .catch((err) => {
          // this.$Message.error(err);
          this.file = null;
          
        });
    },
    getProjectList() {
      this.projectLoadingList = true;
      let data = {
        phone: this.currentUserInfo.login_id,
        pageSize: this.projectListSize,
        pageNumber: this.projectListPage,
        cityId: "440400",
        areaId: this.searchProjectData.areaId || "",
        platformStatus: this.searchProjectData.platformStatus || "",
        name: this.searchProjectData.name || "",
        projectStatus: this.searchProjectData.projectStatus || "",
        safeCorpNum: this.searchProjectData.safeCorpNum || "",
        recordCode: this.searchProjectData.recordCode || "",
        buildCorpName: this.searchProjectData.buildCorpName || "",
        contractorCorpName: this.searchProjectData.contractorCorpName || "",
      };
      this.$fetch("/sapi/project/getStatProjects", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.projectDataList = res.resList;
            this.projectListTotal = res.total;
          } else {
            this.$Message.error("获取列表失败");
          }
          this.projectLoadingList = false;
        })
        .catch((err) => {
         // this.$Message.error("获取列表失败");
          this.projectLoadingList = false;
        });
    },
    toDetail(data) {
      console.log(data);
      this.projectDetailData = JSON.parse(JSON.stringify(data));
      this.projectDetailBackUpData = JSON.parse(JSON.stringify(data));
      this.projectDetailFlag = true;
    },
    cancelDetail() {
      this.projectDetailFlag = false;
      this.projectDetailData = JSON.parse(JSON.stringify(this.projectDetailBackUpData));
    },
    cancelPersonnelDetail() {
      this.personnelDetailFlag = false;
    },
    toDelProject(){
      let data = this.projectDetailData
      this.$fetch("/sapi/project/delStatProject", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.$Message.success("删除成功");
            this.projectDetailFlag = false
            this.projectListPage = 1;
            this.searchProjectData = {
              // cityId: '',
              areaId: "",
              platformStatus: "", //平台状态
              name: "", //项目名
              projectStatus: "", //项目状态
              safeCorpNum: "", //工程序号
              recordCode: "", //施工许可证号
              buildCorpName: "", //建设单位
              contractorCorpName: "", //施工单位
            };
            this.getProjectList();
          } else {
            this.$Message.error("操作失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("操作失败");
        });
    },
    toSaveProject(){
      let data = this.projectDetailData
      this.$fetch("/sapi/project/editStatProject", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.$Message.success("修改成功");
            this.projectDetailFlag = false
            this.projectListPage = 1;
            this.searchProjectData = {
              // cityId: '',
              areaId: "",
              platformStatus: "", //平台状态
              name: "", //项目名
              projectStatus: "", //项目状态
              safeCorpNum: "", //工程序号
              recordCode: "", //施工许可证号
              buildCorpName: "", //建设单位
              contractorCorpName: "", //施工单位
            };
            this.getProjectList();
          } else {
            this.$Message.error("操作失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("操作失败");
        });
    },
    getPersonnelList() {
      this.personnelListLoading = true;
      let data = {
        cityId: "440400",
        name: this.searchPersonnelName || "",
        pageSize: this.personnelListSize,
        pageNumber: this.personnelListPage,
      };
      this.$fetch("/sapi/mutliUser/searchCityMultiUser", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.personnelDataList = res.resList;
            this.personnelListTotal = res.total;
          } else {
            this.$Message.error("获取列表失败");
          }
          this.personnelListLoading = false;
        })
        .catch((err) => {
         // this.$Message.error("获取列表失败");
          this.personnelListLoading = false;
        });
    },
    toPersonnelDetail(data) {
      console.log(data);
      let alist = [];
      if (data.areaIds.split(",")[0]) {
        data.areaIds.split(",").forEach((val, index) => {
          alist.push(val);
        });
      }
      this.currentAreaList = alist;
      this.personnelDetailData = data;
      this.personnelDetailBackUpData = JSON.parse(JSON.stringify(data));
      this.personnelDetailFlag = true;
    },
    handlePersonnelPageChange(e) {
      this.personnelListPage = e;
      this.getPersonnelList();
    },
    handlePersonnelSearch(type){
      if(type == 'search'){
        this.personnelListPage = '1'
        this.getPersonnelList();
      }
      if(type == 'reset'){
        this.personnelListPage = '1'
        this.searchPersonnelName = ''
        this.getPersonnelList();
      }
    },
    toSavePersonnelData() {
      let alist = this.currentAreaList;
      let pdata = this.personnelDetailData;
      if (alist.length < 1) {
        this.$Message.warning("请选择管理区域");
        return false;
      }
      let data = [];
      alist.forEach((val, index) => {
        data.push({
          name: pdata.name,
          phone: pdata.phone,
          icorpid: this.currentUserInfo.icorpid,
          irootcorpid: this.currentUserInfo.irootcorpid,
          userId: this.currentUserInfo.id,
          roleId: '10',
          provinceId: "440000",
          cityId: "440400",
          areaId: val,
        });
      });
      // console.log(data)
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否修改管理人员信息</p>`,
        onOk: () => {
          this.$fetch("/sapi/mutliUser/setCityMultiUser", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("信息修改成功");
              this.personnelListPage = 1;
              this.searchPersonnelName = "";
              this.getPersonnelList();
              this.personnelDetailFlag = false;
            } else {
              this.$Message.info(res.msg || "操作失败");
              this.personnelDetailFlag = false;
            }
            // console.log(res)
          });
        },
        onCancel: () => {
          // this.teamTasksDataChange = !this.teamTasksDataChange;
        },
      });
    },
    toDelPersonnelData() {
      let data = {
        phone: this.personnelDetailData.phone,
        cityId: this.personnelDetailData.cityId
      }
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否删除管理人员信息</p>`,
        onOk: () => {
          this.$fetch("/sapi/mutliUser/delCityMultiUser", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("删除成功");
              this.personnelListPage = 1;
              this.searchPersonnelName = "";
              this.getPersonnelList();
              this.personnelDetailFlag = false;
            } else {
              this.$Message.info(res.msg || "操作失败");
              this.personnelDetailFlag = false;
            }
            // console.log(res)
          });
        },
        onCancel: () => {
          // this.teamTasksDataChange = !this.teamTasksDataChange;
        },
      });
    },
    toAddPersonnel() {
      this.personnelAddFlag = true
    },
    toAddPersonnelData(){
      console.log(this.addPersonnelData)
      let pdata = this.addPersonnelData
      if(!pdata.areaList[0] || !pdata.name || !pdata.phone){
        this.$Message.warning('请填写完整信息')
        return false
      }
      let data = []
      pdata.areaList.forEach(val=>{
        data.push({
          name: pdata.name,
          phone: pdata.phone,
          icorpid: this.currentUserInfo.icorpid,
          irootcorpid: this.currentUserInfo.irootcorpid,
          userId: this.currentUserInfo.id,
          roleId: '10',
          provinceId: "440000",
          cityId: "440400",
          areaId: val,
        })
      })
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否添加管理人员信息</p>`,
        onOk: () => {
          this.$fetch("/sapi/mutliUser/setCityMultiUser", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("添加成功");
              this.personnelListPage = 1;
              this.searchPersonnelName = "";
              this.getPersonnelList();
              this.personnelAddFlag = false;
            } else {
              this.$Message.info(res.msg || "操作失败");
              this.personnelAddFlag = false;
            }
            // console.log(res)
          });
        },
        onCancel: () => {
          // this.teamTasksDataChange = !this.teamTasksDataChange;
        },
      });
    },
    cancelAddPersonnel(){
      this.addPersonnelData = {
        name: '',
        phone: '',
        areaList: []
      }
      this.personnelAddFlag = false
    },
    toOverView(){
      let routeUrl = this.$router.resolve({
                path: '/project/overView'
            });
          window.open(routeUrl.href, '_blank');
    }
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.console {
  display: flex;
  .left-nav {
    width: 260px;
    min-width: 260px;
    overflow-y: auto;
    background: #fff;
    // background: #515a6e;
    border-right: 1px solid #dcdee2;
    height: 100vh;
    .navbtnbox{
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: center;
      .btn-style{
        color: #fff;
        background-color: #2d8cf0;
        border-color: #2d8cf0;
        padding: 8px 32px;
        margin: 8px auto;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .right-main {
    padding: 10px;
    // width: 100%;
    flex: 1;
    .bread-box {
      margin-bottom: 10px;
    }
    .content-box {
      display: flex;
      // transition: all 1s;
      min-height: 600px;
      .cb-btn-box {
        display: flex;
      }
      .page-box {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
      }
      .edit-btn-box {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        .ebb-style {
          margin-right: 10px;
        }
      }
      .second-title {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        .shu {
          width: 5px;
          height: 14px;
          background: #409eff;
          margin-right: 6px;
        }
      }
      .c-btnbox {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        padding-left: 14px;
        .c-btnbox-item {
          display: flex;
          .mb-item {
            display: flex;
            align-items: center;
            margin-right: 14px;
            .label {
              width: 100px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
.detail-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-box {
    // width: 700px;
    padding-right: 24px;
    background: #fff;
    // height: 100px;
    border-radius: 6px;
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 16px;
      font-size: 18px;
    }
    .infobox {
      display: flex;
      flex-direction: column;
      padding: 14px 16px;
      .i-item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        margin-left: 24px;
        .label {
          width: 140px;
        }
      }
    }
    .btnbox {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 14px 0 14px 24px;
    }
  }
}
.tab-btnbox {
  display: flex;
  margin-bottom: 14px;
  margin-left: 14px;
}
.tab-textarea {
  margin-left: 14px;
}
.bgimg {
  position: absolute;
  z-index: 1;
  opacity: 0.03;
  width: 450px;
  right: -100px;
  bottom: -100px;
  img {
    width: 100%;
    height: auto;
  }
}
.aa-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .label {
    width: 120px;
  }
}
// 鼠标移入效果
@keyframes myCardHover {
  0% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15),
      0 6px 20px 0 rgba(0, 0, 0, 0.14);
  }
}
.count-part:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
</style>